<template>
  <el-select
      filterable
      remote
      clearable
      :disabled="disabled"
      :value="value"
      :value-key="valueKey"
      :placeholder="placeholder"
      :remote-method="getPartition"
      :multiple="multiple"
      :multiple-limit="multipleLimit"
      @change="handleChange"
  >
    <template v-if="simpleValue">
      <el-option
          v-for="item in partitionOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
      ></el-option>
    </template>
    <template v-else>
      <el-option
          v-for="item in partitionOptions"
          :key="item.id"
          :label="item.name"
          :value="item"
      ></el-option>
    </template>
  </el-select>
</template>
<script>
import {mapActions} from 'vuex'
export default {
  name: 'PartitionSite',
  props: {
    value: {
      type: [Number, String, Array, Object],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请先选择商品类型'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    multipleLimit: {
      type: Number,
      default: 0
    },
    defaultOptions: {
      type: Array,
      default: () => []
    },
    initOptions: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String,
      default: ''
    },
    simpleValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      partitionList: [],
      selectedId: ''
    }
  },
  watch: {
    initOptions: {
      handler(val) {
        if (val) {
          this.partitionList = val
        }
      },
      immediate: true
    },
    type(n) {
      if (n) {
        this.getPartition()
      }
    }
  },
  computed: {
    partitionOptions() {
      if (this.defaultOptions && this.defaultOptions.length) {
        return [...this.defaultOptions, ...this.partitionList]
      }
      return this.partitionList
    },
    valueKey() {
      return this.simpleValue ? 'value' : 'name'
    }
  },
  created() {
    setTimeout(() => {
      if (this.type) {
        this.getPartition('')
      }
    }, 100)
  },
  methods: {
    ...mapActions(['getProductList']),
    async getPartition(productName) {
      if (this.type) {
        const res = await this.getProductList({
          page: 1,
          size: 100,
          productName,
          type: this.type
        })
        const { recordList } = res
        this.partitionList = recordList || []
        if (this.defaultValue && !this.simpleValue) {
          const obj = this.partitionOptions.find(item => item.id === this.defaultValue)
          this.handleChange(obj)
        }
      }
    },
    handleChange(v) {
      this.selectedId = v
      this.$emit('input', v)
      this.$emit('change', v)
    }
  }
}
</script>
