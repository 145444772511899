<template>
    <el-dialog
            :title="title"
            :visible.sync="createDialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :append-to-body="true"
            v-drag-dialog
            @close="close">
        <div class="pageContainer">
            <el-form label-position="right"
                     :model="formData"
                     :rules="productRecommendRules"
                     ref="ProductForm"
                     label-width="100px"
                     element-loading-background="rgba(0, 0, 0, 0.8)">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="商品类型" prop="type">
                    <el-select v-model="formData.type" placeholder="请选择商品类型" class="widthCover">
                      <el-option v-for="item in PRODUCT_TYPE"
                                 :key="item.key"
                                 :value="item.key"
                                 :label="item.label"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="商品" prop="type">
                    <product-select v-model="formData.product"
                                    :type="formData.type"
                                    :default-value="formData.productId"
                                    @change="handleProductChange"></product-select>
                  </el-form-item>
                </el-col>
              </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="商品推荐序号" prop="orderNo">
                            <el-input-number v-model="formData.orderNo"
                                             class="numberInput"
                                             style="width: 100%;text-align: left"
                                             :prefix="2"
                                             :controls="false"
                                             placeholder="请输入商品价格"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row type="flex" justify="center">
                <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
  import {mapActions} from 'vuex'
  import ProductSelect from './product-select.vue'
  import {
    productRecommendRules,
    resetProductRecommend,
    execute,
    PRODUCT_TYPE
  } from '../option'

  export default {
    name: 'addOrEditProductRecommend',
    components: {
      ProductSelect
    },
    data() {
      return {
        PRODUCT_TYPE,
        optType: 'add',
        createDialogVisible: false,
        callback: null,
        formData: resetProductRecommend(),
        productRecommendRules,
        addingLoading: false,
        supplierList: []
      }
    },
    computed: {
      title() {
        return this.optType === 'add' ? '新增商品推荐' : '编辑商品推荐'
      }
    },
    created() {
      this.tryGetSupplier('')
    },
    methods: {
      ...mapActions(['saveProductRecommend', 'getProductRecommendById', 'updateProductRecommend', 'getSupplierList']),
      show(options) {
        this.optType = options.optType
        this.callback = options.callback
        if (this.optType === 'edit') {
          this.tryGetProductById(options.detail.id)
        } else {
          this.formData = resetProductRecommend()
        }
        this.createDialogVisible = true
        this.$nextTick(() => {
          this.$refs.ProductForm.clearValidate()
        })
      },
      close() {
        this.createDialogVisible = false
      },
      // 展示图片
      showImg(imgUrl, title) {
        execute('showImg', {
          imgUrl,
          title
        })
      },
      // 商品change
      handleProductChange(value) {
        console.log(value)
        if (value) {
          this.formData.cover = value.cover
          this.formData.productId = value.id
          this.formData.name = value.name
        } else {
          this.formData.productId = ''
        }
      },
      // 上传文件
      handleUploadCover(url) {
        this.formData.cover = url
      },
      tryGetProductById(id) {
        this.getProductRecommendById(id).then(res => {
          this.formData = res || this.formData
        })
      },
      tryUpdateProduct() {
        this.addingLoading = true
        this.updateProductRecommend(this.formData).then(() => {
          this.$message.success('操作成功')
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      trySubmit() {
        if (this.addingLoading) {
          return
        }
        this.$refs.ProductForm.validate((valid) => {
          if (valid) {
            const api = this.optType === 'add' ? this.trySaveProduct : this.tryUpdateProduct
            api()
          }
        })
      },
      trySaveProduct() {
        this.addingLoading = true
        this.saveProductRecommend(this.formData).then(() => {
          this.$message.success('提交成功')
          this.resetProductRecommend()
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      tryGetSupplier(keyword) {
        this.getSupplierList({
          page: 1,
          size: 30,
          name: keyword
        }).then(res => {
          const { recordList } = res
          this.supplierList = recordList
        })
      },
      resetProductRecommend() {
        this.formData = resetProductRecommend()
      }
    }
  }
</script>
<style lang="scss">
    .numberInput {
        width: 100%;

        .el-input {
            .el-input__inner {
                text-align: left;
            }
        }
    }
</style>
<style scoped lang="scss">
    @import "../../../scss/elementVar";

    .pageContainer {
        padding: 20px;
    }

    .tagWrap {
        margin-bottom: 20px;
    }

    .uploadBtn {
        padding: 30px 0;
        width: 150px;
        height: 100px;
        box-sizing: border-box;
        text-align: center;
        color: $--color-info;
        border: 2px dashed $--color-info;
        opacity: 0.7;
        border-radius: 4px;
        transition: all linear 100ms;
    }

    .uploadBtn:hover {
        opacity: 1;
    }

    .uploadBtnIcon {
        font-weight: bold;
        font-size: 30px;
    }

    .uploadBtnText {
        line-height: 10px;
        font-size: 14px;
    }

    .imgContainer {
        float: left;
        margin: 0 10px 10px 0;
    }

    .smallImg {
        width: 40px;
    }
    .widthCover{
        width: 100%;
    }
</style>
